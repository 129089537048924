import { axios } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/users/${params.id}/update_others`, { user: params.user })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/users/${params.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
