<template>
  <div id="user-list">
    <!-- app drawer -->
    <user-add
      v-model="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @refetch-data="fetchUsers"
    ></user-add>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Accounts List
      </v-card-title>

      <v-divider></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap">
        <v-select
          v-model="roleFilter"
          placeholder="Select Role"
          :items="roleOptions"
          item-text="name"
          item-value="id"
          outlined
          clearable
          dense
          hide-details
          class="my-3"
        ></v-select>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="my-3"
            @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New User</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="userListTable"
        :loading="loading"
        :disable-sort="true"
        class="text-no-wrap"
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :src="require(`@/assets/images/avatars/${item.avatar}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ item.initials }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <div class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                {{ item.name }}
              </div>
              <small>@{{ item.nickname }}</small>
            </div>
          </div>
        </template>

        <!-- status -->
        <template #[`item.is_active`]="{item}">
          <div class="d-flex align-center">
            <v-chip
              small
              :color="item.is_active ? 'success' : 'error'"
              class="v-chip-light-bg"
              :class="item.is_active ? 'success--text' : 'error--text'"
            >
              {{ item.is_active ? 'Active' : 'Inactive' }}
            </v-chip>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.role)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.role)}--text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveUserRoleVariant(item.role)"
              >
                {{ resolveUserRoleIcon(item.role) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ item.role }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-icon
            class="mr-2"
            @click="editableUser = item; isEditDialogVisible = true"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-icon @click="deletableUser = item; isDeleteDialogVisible = true">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- Update dialog -->
    <v-dialog
      v-model="isEditDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mb-4">
          <span class="font-weight-semibold text-base text--primary">Edit User</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isEditDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="updateUser()"
        >
          <v-card-text>
            <v-text-field
              v-model="editableUser.name"
              outlined
              dense
              :rules="[validators.required]"
              label="Full Name"
              placeholder="John Doe"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="editableUser.nickname"
              :rules="[validators.required]"
              outlined
              dense
              label="Nickname"
              placeholder="Nickname"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="editableUser.email"
              :rules="[validators.required]"
              outlined
              dense
              type="email"
              label="Email"
              placeholder="Email"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="editableUser.password"
              outlined
              dense
              type="text"
              label="Password"
              placeholder="Password"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-alert
              color="warning"
              text
              class="mb-6"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    Only change user password when requested
                  </p>
                </div>
              </div>
            </v-alert>

            <v-text-field
              v-model="editableUser.contact_number"
              :rules="[validators.required]"
              outlined
              dense
              type="text"
              label="Contact"
              placeholder="Contact"
              hide-details="auto"
              prefix="+673"
              class="mb-6"
            ></v-text-field>

            <v-select
              v-model="editableUser.role_id"
              :rules="[validators.required]"
              label="Role"
              :items="roleOptions"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              class="mb-6"
            >
            </v-select>

            <v-select
              v-model="editableUser.is_active"
              label="Account Status"
              :items="statusOptions"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details="auto"
              class="mb-6"
            >
            </v-select>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              :loading="editLoading"
              :disabled="editLoading || !valid"
              type="submit"
              class="me-3"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>

        <v-card
          v-if="errors.length != 0"
          class="mb-6"
        >
          <v-card-text>
            <Alert
              :messages="errors"
              color="error"
            ></Alert>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>

    <!-- Delete dialog -->
    <v-dialog
      v-model="isDeleteDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Remove {{ deletableUser.nickname }}?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isDeleteDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          All records for this user will be archived. You won't be able to see, edit or search for records created by this user apart from Log entries.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="deleteLoading"
            :disabled="deleteLoading"
            class="mt-3"
            @click="deleteUser()"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiDnsOutline,
  mdiPencilOutline,
  mdiClose,
  mdiAlertOutline,
} from '@mdi/js'
import store from '@/store'
import {
  onMounted,
  onUnmounted,
  ref,
  watch,
  inject,
  computed,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'

// sidebar
import UserAdd from './UserAdd.vue'
import userStoreModule from './userStoreModule'

export default {
  components: {
    UserAdd,
  },
  setup() {
    // Initializers
    const USER_ADMIN_STORE_MODULE_NAME = 'admin-user'
    const snackbarService = inject('snackbarService')

    if (!store.hasModule(USER_ADMIN_STORE_MODULE_NAME)) store.registerModule(USER_ADMIN_STORE_MODULE_NAME, userStoreModule)

    // Properties
    const isAddNewUserSidebarActive = ref(false)
    const userListTable = ref([])
    const tableColumns = [
      { text: 'User', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Contact No.', value: 'contact_number' },
      { text: 'Status', value: 'is_active' },
      { text: 'Role', value: 'role' },
      {
        text: 'Action',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]
    const roleFilter = ref(1)
    const loading = ref(false)
    const editLoading = ref(false)
    const deleteLoading = ref(false)
    const isEditDialogVisible = ref(false)
    const isDeleteDialogVisible = ref(false)
    const editableUser = ref({})
    const deletableUser = ref({})
    const valid = ref(false)
    const errors = ref([])
    const statusOptions = [
      { name: 'Active', value: true },
      { name: 'Inactive', value: false },
    ]

    // Methods
    const resolveUserRoleVariant = role => {
      if (role === 'Clerk') return 'primary'
      if (role === 'Auditor') return 'info'
      if (role === 'Admin') return 'error'

      return 'primary'
    }
    const resolveUserRoleIcon = role => {
      if (role === 'Clerk') return mdiAccountOutline
      if (role === 'Auditor') return mdiPencilOutline
      if (role === 'Admin') return mdiDnsOutline

      return mdiAccountOutline
    }
    const fetchRoles = () => {
      store
        .dispatch('fetchRoles')
        .then(response => {
          const { data } = response.data
          store.dispatch('setRoles', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching roles. Please refresh!')
        })
    }
    const fetchUsers = () => {
      store
        .dispatch('admin-user/fetchUsers', {
          role: roleFilter.value,
        })
        .then(response => {
          const { data } = response.data
          userListTable.value = data
          loading.value = false
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching accounts. Please refresh!')
        })
    }
    const updateUser = () => {
      editLoading.value = true
      store
        .dispatch('admin-user/updateUser', {
          id: editableUser.value.id,
          user: editableUser.value,
        })
        .then(response => {
          const { message } = response.data
          snackbarService.success(message)
          fetchUsers()
          isEditDialogVisible.value = false
          editLoading.value = false
        })
        .catch(error => {
          editLoading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while updating user. Please refresh!')
        })
    }
    const deleteUser = () => {
      deleteLoading.value = true
      store
        .dispatch('admin-user/deleteUser', { id: deletableUser.value.id })
        .then(response => {
          const { message } = response.data
          snackbarService.success(message)
          fetchUsers()
          isDeleteDialogVisible.value = false
          deleteLoading.value = false
        })
        .catch(error => {
          deleteLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while deleting user. Please refresh!')
        })
    }

    // Computed
    const roleOptions = computed(() => store.state.roles)

    // Watch
    watch([roleFilter], () => {
      loading.value = true
      fetchUsers()
    })

    onMounted(() => {
      fetchRoles()
      fetchUsers()
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(USER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(USER_ADMIN_STORE_MODULE_NAME)
    })

    return {
      userListTable,
      editableUser,
      deletableUser,
      isEditDialogVisible,
      isDeleteDialogVisible,
      tableColumns,
      roleFilter,
      roleOptions,
      loading,
      editLoading,
      deleteLoading,
      isAddNewUserSidebarActive,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      fetchUsers,
      updateUser,
      deleteUser,
      errors,
      valid,
      statusOptions,
      validators: { required },

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiPencilOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiClose,
        mdiAlertOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
