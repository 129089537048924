<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New User</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="userData.name"
            outlined
            dense
            :rules="[validators.required]"
            label="Full Name"
            placeholder="John Doe"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.nickname"
            :rules="[validators.required]"
            outlined
            dense
            label="Nickname"
            placeholder="Nickname"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.email"
            :rules="[validators.required]"
            outlined
            dense
            type="email"
            label="Email"
            placeholder="Email"
            hide-details="auto"
            suffix="@niagamas.com"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.password"
            :rules="[validators.required]"
            outlined
            dense
            type="text"
            label="Password"
            placeholder="Password"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.password_confirmation"
            :rules="[validators.required]"
            outlined
            dense
            type="text"
            label="Password Confirmation"
            placeholder="Password Confirmation"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.contact_number"
            :rules="[validators.required]"
            outlined
            dense
            label="Contact"
            placeholder="Contact"
            hide-details="auto"
            prefix="+673"
            class="mb-6"
          ></v-text-field>

          <v-select
            v-model="userData.role_id"
            :rules="[validators.required]"
            label="Role"
            :items="roleOptions"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-select
            v-model="userData.is_active"
            label="Account Status"
            :items="statusOptions"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Add
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetuserData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>

      <v-card
        v-if="errors.length != 0"
        class="mb-6"
      >
        <v-card-text>
          <Alert
            :messages="errors"
            color="error"
          ></Alert>
        </v-card-text>
      </v-card>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
import store from '@/store'
import { ref, inject } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import Alert from '@/components/Alert'

export default {
  components: {
    Alert,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const snackbarService = inject('snackbarService')
    const blankUserData = {
      name: '',
      nickname: '',
      email: '',
      password: '',
      password_confirmation: '',
      contact_number: '',
      role_id: null,
    }
    const statusOptions = [
      { name: 'Active', value: true },
      { name: 'Inactive', value: false },
    ]

    const valid = ref(false)
    const form = ref(null)
    const errors = ref([])

    const validate = () => form.value.validate()
    const resetForm = () => form.value.reset()
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }
    const onSubmit = () => {
      if (valid.value) {
        userData.value.email = `${userData.value.email}@niagamas.com`
        store
          .dispatch('admin-user/createUser', userData.value)
          .then(response => {
            const { message } = response.data
            snackbarService.success(message)

            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
            resetuserData()
          })
          .catch(error => {
            errors.value = error.response.data.errors
            snackbarService.error(error.response.data.message || 'Something went wrong while adding new user. Please refresh!')
          })
      } else validate()
    }

    return {
      statusOptions,
      resetuserData,
      form,
      errors,
      onSubmit,
      userData,
      valid,
      validate,

      // validation
      validators: { required },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
